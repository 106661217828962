import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      The page you are looking is not found in our website. Please feel to
      contact us if you are looking for some informtion
    </p>
  </Layout>
);

export default NotFoundPage;
